import React from "react";
import styles from "./filter.module.scss";
import { Card, Form, Collapse, Row, Col, Button } from "react-bootstrap";
import { apis } from '../../services/Api';
import filter from "../../assets/images/filter.svg";
import Multiselect from 'multiselect-react-dropdown';


const Filter = (props) => {
  const [open, setOpen] = React.useState(false);
  //filter state
  let [tempDomain, setTempDomain] = React.useState([]);
  let [tempSortBy, setTempSortBy] = React.useState();
  const [tempSelectedTag, setTempSelectedTag] = React.useState([]);
  //api result store
  let [domainList, setDomainList] = React.useState([]);
  let [tagsList, setTagsList] = React.useState([]);
  let [sortByList] = React.useState([
    {
      name: 'Favorite',
      value: 'defaultValue',
    },
    {
      name: 'Time',
      value: 'time'
    },
    {
      name: 'Title',
      value: 'name'
    },
    {
      name: 'Like',
      value: 'like'
    }
  ]);

  const handleResat = () => {
    props.handleResat();
  }

  const handShortByChange = async (e) => {
    console.log(e.target.value);
    if (e.target.value === 'defaultValue') {
      setTempSortBy(null);
    } else {
      setTempSortBy(e.target.value);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    let submittedTags = [];
    tempSelectedTag.forEach(element => {
      submittedTags.push(element.name);
    });

    props.handleSubmit({
      submittedTags: submittedTags,
      submittedDomain: tempDomain,
      submittedSortBy: tempSortBy
    });

  }

  const onSelectTag = (selectedList, selectedItem) => {
    setTempSelectedTag(selectedList)
  }

  const onRemoveTag = (selectedList, removedItem) => {
    setTempSelectedTag(selectedList)
  }

  const apiCall = async () => {
    let [resultTags, resultLobs] = await Promise.all([apis.getTags(), apis.getLobs()]);
    //unescape_name key is added for display unescape tag name
    let unescapeTagName = resultTags.data?.map(e => { return { ...e, unescape_name: unescape(e.name) } })
    setTagsList(unescapeTagName);
    setDomainList(resultLobs.data);
  }

  const handleDomainChange = (e) => {
    // console.log(e.target.checked);
    // console.log(e.target.name);
    if (e.target.checked) {
      const index = tempDomain.indexOf(e.target.name);
      if (index === -1) {
        let a = tempDomain;
        a.push(e.target.name)
        setTempDomain(a);
      }
    } else {
      const index = tempDomain.indexOf(e.target.name);
      if (index > -1) {
        let a = tempDomain;
        a.splice(index, 1)
        setTempDomain(a);
      }
    }
  }

  React.useEffect(() => {
    apiCall();
  }, []);

  return (
    <>
      <a
        onClick={() => setOpen(!open)}
        aria-controls="example-collapse-text"
        aria-expanded={open}
        className="cursor-pointer"
      >
        <img alt="Filter" src={filter} />
        <Form.Label>Filter</Form.Label>
      </a>
      <div>
        <Collapse in={open} dimension="width">
          <div id="example-collapse-text">
            <Card body style={{ width: "400px" }}>
              <div className="overflow-auto scroll-collapse">
                <div className="d-flex justify-content-end p-2 cursor-pointer">
                  <i
                    className="bi bi-x-lg close p-2"
                    onClick={() => setOpen(!open)}
                  ></i>
                </div>
                {/* <div className="divider-hr"></div>
                <div className="title">CoE</div> */}
                <div className="checks">
                  <Form onSubmit={handleSubmit} data-test="Filter-from" >
                    {["checkbox"].map((type) => (
                      <div key={`inline-${type}`} className="mb-3">
                        {/* <Row xs={1} md={2}>
                          <Col>
                            <Form.Check
                              inline
                              label="All CoE"
                              name="group1"
                              type={type}
                              id={`inline-${type}-1`}
                              onChange={(e) => console.log(e)}
                            />
                          </Col>
                          <Col>
                            <Form.Check
                              inline
                              label="Content CoE"
                              name="group2"
                              type={type}
                              id={`inline-${type}-2`}
                            />
                          </Col>
                          <Col>
                            <Form.Check
                              inline
                              label="HealthTech CoE"
                              name="group3"
                              type={type}
                              id={`inline-${type}-3`}
                            />
                          </Col>
                          <Col>
                            <Form.Check
                              inline
                              label="HRTech CoE"
                              name="group4"
                              type={type}
                              id={`inline-${type}-4`}
                            />
                          </Col>
                          <Col>
                            <Form.Check
                              inline
                              label="AI Data CoE"
                              name="group5"
                              type={type}
                              id={`inline-${type}-5`}
                            />
                          </Col>
                          <Col>
                            <Form.Check
                              inline
                              label="Experience Design CoE"
                              name="group6"
                              type={type}
                              id={`inline-${type}-6`}
                            />
                          </Col>
                        </Row> */}
                        {/* <div className="divider-hr"></div> */}
                        <div className="title">Domain</div>
                        <Row xs={1} md={2}>
                          {domainList.map((d, i) => <Col key={i}>
                            <Form.Check
                              inline
                              label={d.name}
                              name={d.name}
                              type={type}
                              id={d.name}
                              onChange={(e) => handleDomainChange(e)}
                              data-test="Filter-domain"
                            />
                          </Col>)}
                        </Row>
                        <div className="divider-hr"></div>
                        <div className="title">Sort By</div>
                        <Row>
                          <Col md={12} className="select-bordered">
                            <select className="form-select" onChange={(e) => handShortByChange(e)} data-test="Filter-short-by">
                              {/* <option>Favorite</option> */}
                              {sortByList.map((e, i) =>
                                <option key={i} value={e.value}> {e.name}</option>
                              )}
                            </select>
                          </Col>
                        </Row>
                        <div className="divider-hr"></div>
                        <div className="title">Tags</div>



                        {/* <Row>
                          <Col>
                            <span className="badge bg-info text-dark selected">Integration</span>
                            <span className="badge bg-info text-dark selected">Chat Bot</span>
                            <div className="badge bg-info text-dark"><span>Experience design</span></div>
                            <span className="badge bg-info text-dark">low code</span>
                            <span className="badge bg-info text-dark">No Code</span>
                          </Col>
                        </Row>
                        <div className="divider-hr"></div> */}




                        <Row>
                          <Multiselect
                            options={tagsList} // Options to display in the dropdown
                            onSelect={onSelectTag} // Function will trigger on select event
                            onRemove={onRemoveTag} // Function will trigger on remove event
                            displayValue="unescape_name" // Property name to display in the dropdown options
                            selectedValues={tempSelectedTag}
                          />
                        </Row>
                        <div className="divider-hr"></div>




                        <Row>
                          <Col className="py-4 btn-row">
                            <Button variant="primary" type="submit" className="ripple" onClick={() => setOpen(!open)} data-test="Filter-apply" >Apply</Button>
                            <Button variant="secondary" className="ripple" type="reset" onClick={() => handleResat()} data-test="Filter-reset">Reset</Button>
                            <Button variant="secondary" className="ripple" onClick={() => setOpen(!open)} data-test="Filter-cancel">Cancel</Button>
                          </Col>
                        </Row>
                      </div>
                    ))}
                  </Form>
                </div>
              </div>
            </Card>
          </div>
        </Collapse>
      </div>
    </>
  );
};

export default Filter;
